/* 
primary color - #F2F3F4

*/

:root {
  --primary-color: #f2f3f4;
  --background-color: #090917;
  --text-primary-color: #854ce6;
  --text-secondary-color: #b1b2b3;
  --text-color: #f2f3f499;
  --bg-color: green;
  --txt-color: white;
  --text-second-color: #8d8d8d;
}
.mgTop10 {
  margin-top: 10px;
}
.pd020 {
  padding: 0 20px;
}
.mgTop20 {
  margin-top: 20px;
}

.cursor-pointer {
  cursor: pointer;
}
.mg020 {
  margin: 0 20px;
}

.mgBottom20 {
  margin-bottom: 20px;
}
.mgTop40 {
  margin-top: 40px;
}
.mgBottom {
  margin-bottom: 20px;
}
.pdTop20 {
  padding-top: 20px;
}

.pdBottom {
  padding-bottom: 20px;
}
.pdBottom20 {
  padding-bottom: 20px;
}
.pdBottom10 {
  padding-bottom: 10px;
}

.star-canvas-wrapper {
  width: 100%;
  height: auto;
  position: absolute;
  inset: 0;
}

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0px !important;
  padding: 0;
  font-family: "Montserrat", sans-serif;
} */

/*  Scroolll bar effect start */
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b1b2b3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #854ce6;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b1b2b3;
}

::-webkit-backdrop-filter {
  blur: 3px saturate(106%);
}

/*  Scroolll bar effect end */

.main-container {
  width: 100%;
  background-color: var(--background-color);
}

/* Navbar section start */
.nav-main-container {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  background-color: var(--background-color);
}
.nav-container {
  width: 100%;
  /* max-width: 1200px; */
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}
.nav-logo-container {
  display: flex;
  align-items: center;
  width: 80%;
  padding: 0 6px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: inherit;
}
.color-primary {
  color: var(--text-primary-color);
}
.color-text {
  color: var(--text-primary-color);
  font-size: 32px;
}

.nav-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 0 6px;
  list-style: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .nav-items {
    display: none;
  }
}
.nav-mobile-menu-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  padding: 0 6px;
  list-style: none;
  width: 100%;
  padding: 12px 40px 24px 40px;
  background: var(--background-color);
  /* background: #09091720; */
  position: absolute;
  top: 80px;
  right: 0;

  transition: all 0.6s ease-in-out;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  /* transform: ${({ isOpen }) =>
    isOpen ? "translateY(0)" : "translateY(-100%)"}; */

  /* opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  z-index: ${({ isOpen }) => (isOpen ? "1000" : "-1000")}; */
}
/* #F2F3F4 */
.nav-item-text {
  color: var(--primary-color);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    color: var(--text-primary-color);
  }
}
.mobile-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.say-hi-container {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 6px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
/* Navbar section end */

.section-container {
  padding-top: 80px;
  padding-bottom: 80px;
  @media screen and (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.section-wrapper {
  /* padding-top: 100px;
  padding-bottom: 100px; */
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
}

/* Banner */

.section-banner-container {
  padding: 80px 30px;
  @media (max-width: 960px) {
    padding: 66px 16px;
  }
  @media (max-width: 640px) {
    padding: 32px 16px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 95%, 0 100%);
  }
}
.section-banner .title {
  font-weight: 700;
  font-size: 50px;
  color: var(--primary-color);
  line-height: 68px;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 960px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 8px;
  }
}

.section-banner .text-loop {
  font-weight: 600;
  font-size: 32px;
  display: flex;
  gap: 12px;
  color: var(--primary-color);
  line-height: 68px;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 960px) {
    font-size: 22px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}
.section-banner span {
  cursor: pointer;
  color: var(--text-primary-color);
  /* color: var(--primary-color) */
}

.section-banner .description {
  /* color: var(--text-primary-color) + 90; */
  color: var(--primary-color);
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 42px;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 960px) {
    font-size: 16px;
    line-height: 32px;
  }
  /* color: var(--primary-color) */
}

/* Banner end */

/* skill section start */
.skill-main-section {
  position: relative;
}
.skill-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
}

.skill-section {
  width: 100%;
  max-width: 500px;
  background-color: rgba(17, 25, 40, 0.83);
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 36px;

  @media (max-width: 768px) {
    max-width: 400px;
    padding: 10px 36px;
  }

  @media (max-width: 500px) {
    max-width: 330px;
    padding: 10px 36px;
  }
}

/* skill section end */

/*  Exprience Section start */

.experience-top-container {
  width: 100%;
  display: flex;
  max-width: 100%;
  gap: 12px;
}
.experience-top-logo-image {
  height: 50px;
  border-radius: 10px;
  margin-top: 4px;

  @media only screen and (max-width: 768px) {
    height: 40px;
  }
}
.experience-top-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.experience-top-role {
  font-size: 18px;
  font-weight: 600px;
  color: var(--text-secondary-color);

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}
.experience-top-company {
  font-size: 14px;
  font-weight: 500px;
  color: var(--text-secondary-color);
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}
.experience-top-date {
  font-size: 12px;
  font-weight: 400px;
  color: var(--text-secondary-color);

  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
}
.experience-description {
  color: var(--text-color);
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}
.experience-description-text {
  display: -webkit-box;
  max-width: 100%;
}
.experience-skill-container {
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: 5px;
}
.experience-skill-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

/* Tag */
.ex-tag-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
}
.ex-tag {
  font-size: 12px;
  font-weight: 400;
  /* color: var(--text-secondary-color); */
  color: var(--primary-color);
  background-color: #854ce630;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1.5px solid #854ce6;
}
/*Tag */
.experience-skill {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-color);
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}
/*  Exprience Section end */

/* project section start */
.project-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.project-main-container {
  position: relative;
  padding-top: 40px;
}
.project-main-container .view-main-container {
  background-color: #090917;
  margin: 10px auto;
}
.project-detail-main-container {
  display: flex;
  align-items: center;
}

.project-detail-slider {
  /* padding: 10px; */
}

.project-page-container {
  margin: 3% auto;
  padding: 20px;
  box-shadow: rgba(255, 255, 255, 0.15) 10px 4px 24px;
}
.swiper-pagination-bullet {
  background-color: var(--primary-color);
}
.swiper-pagination-bullet-active {
  background-color: var(--text-primary-color);
}
.project-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills-header {
  font-size: 60px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.content {
  font-size: 20px;
  color: var(--text-second-color);
}

.project-detail-descrption-container {
  margin-left: 50px;
  padding-top: 80px;
  font-family: "Google Sans Regular";
}
.project-detail-descrption-sub-container {
  display: flex;
  flex-wrap: wrap;
}

.project-detail-main-container > * {
  flex: 1;
  margin-bottom: 30px;
}

.project-detail-img-container > * {
  max-width: 100%;
  width: 90%;
  height: auto;
}

.project-detail-descrption-heading {
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
  font-family: "Google Sans Regular";
}

/* Media Query */
@media (max-width: 1380px) {
  .project-detail-descrption-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .project-detail-descrption-heading {
    font-size: 30px;
    text-align: center;
  }

  .project-detail-main-container {
    flex-direction: column;
  }
  .project-detail-descrption-container {
    margin-left: 0px;
    margin: 20px;
  }
  .content {
    font-size: 16px;
  }

  .project-detail-img-container {
    order: 2;
  }
  .project-detail-img-container > * {
    margin-top: 0px;
  }
}

/*  */
/* project section end */

/* Blog section start */
.blog-section-main-container {
  /* width: 330px;
  height: 490px; */
  margin-bottom: 40px;
  background-color: #171721;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding: 26px 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
  }
}
.blog-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-secondary-color);
  /* color: ${({ theme }) => theme.text_secondary}; */
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-description {
  font-weight: 400;
  color: #b1b2b399;
  /* color: var(--text-secondary-color); */
  overflow: hidden;
  margin-top: 8px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.blog-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
}

.blog-tag-content {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-primary-color);
  background: #854ce630;
  /* background-color: ${({ theme }) => theme.primary + 15}; */
  padding: 2px 8px;
  border-radius: 10px;
}
/* Blog section end */

/* Contact start */

.contact-section {
  position: relative;
}
.contact-card-container {
  margin-bottom: 20px;
}
/* .contact-card-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
} */

.contact-card-section {
  background-color: rgba(17, 25, 40, 0.83);
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 36px;

  @media (max-width: 768px) {
    max-width: 400px;
    padding: 10px 36px;
  }

  @media (max-width: 500px) {
    max-width: 330px;
    padding: 10px 36px;
  }
}
.contact-card-main-container {
  width: 100%;
  display: flex;
  max-width: 100%;
  gap: 20px;
}
.contact-card-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-item: center; */
}

.card-title {
  font-size: 20px;
  color: var(--text-primary-color);
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}
.card-detail {
  font-size: 14px;
  font-weight: 500;
  color: white;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}
/* Contact section end */

/* footer start */
.footer .span-heart {
  padding: 0.3rem;
}
.footer p {
  font-size: 0.9rem;
  padding: 0 1rem;
  color: white;
}
.footer-container {
  /* padding: 2rem 0; */
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
}

/* Button */
.button-default {
  border: 1px solid var(--primary-color);
  color: var(--text-color);
}
.button-default:hover {
  background-color: var(--text-primary-color);
  color: var(--text-color);
  border-color: var(--text-primary-color);
}
.btn-outline-primary {
  color: var(--text-primary-color);
  border-color: var(--text-primary-color);
}
.btn-outline-primary:hover {
  color: var(--primary-color);
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
  /* color: var(--text-primary-color);
  border-color: var(--text-primary-color); */
}
.button-primary {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;
  width: 95%;
  max-width: 300px;
  text-align: center;
  padding: 16px 0;

  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  box-shadow: 20px 20px 60px #1f2634, -20px -20px 60px #1f2634;
  border-radius: 50px;
  font-weight: 600;
  font-size: 20px;
  color: white;

  @media (max-width: 640px) {
    padding: 12px 0;
    font-size: 18px;
  }

  &:hover {
    transform: scale(1.05);
    transition: all 0.4s ease-in-out;
    box-shadow: 20px 20px 60px #1f2634;
    /* filter: brightness(1); */
  }
}

.button-secondary-button {
  border: 1px solid var(--text-primary-color);
  color: var(--text-primary-color);
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.6s ease-in-out;
  text-decoration: none;
  &:hover {
    background-color: var(--text-primary-color);
    color: var(--primary-color);
  }
}

/* Card */
.service-card {
  margin: 20px auto;

  cursor: pointer;
  background-color: rgba(17, 25, 40, 0.83);
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;

  border-radius: 10px;

  padding: 26px;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
  }
}
.service-card-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-color);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
/* card  Finish*/

/*  Section Header */
.section-header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  padding-top: 20px;
  margin-bottom: 30px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
}
.section-header-title {
  font-size: 52px;
  font-weight: 600;
  color: var(--text-primary-color);
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
}
.section-header-description {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-secondary-color);
  @media (max-width: 768px) {
    font-size: 16px;
  }
}
/*  section Header Finish */

/* social Icon start */

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 2rem;
  z-index: 3;
  & > *:not(:last-child) {
    margin: 0.5rem 0;
  }
}
.line-container {
  width: 2px;
  height: 6rem;
  background-color: #fcf6f4;
}
/* social Icon end */
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-found-container {
  position: relative;
}
.not-found-image {
  max-width: 400px;
  width: 100%;
}
.not-found-container .text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--text-second-color);
}

.not-found-container a {
  color: var(--text-primary-color);
  text-decoration: none;
}

/* Poject Page */

.view-list-container {
  /* max-height: 500px; */
  margin: 20px auto;
  cursor: pointer;
  background-color: rgba(17, 25, 40, 0.83);
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 20px;
  padding: 26px;
  transition: all 0.5s ease-in-out;
  margin-bottom: 40px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  padding: 26px 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
  }
}

/*  proect slicder */

.project-slider-container {
  background-color: #171721;
  cursor: pointer;
  border-radius: 10px;

  /* overflow: hidden; */
  /* padding: 26px 20px; */
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
  }
}

.project-slider-title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: var(--text-secondary-color);
  /* color: ${({ theme }) => theme.text_secondary}; */
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  margin-bottom: 20px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .swiper-slide.swiper-slide-active {
  background-color: white;
} */

.swiper-slide.swiper-slide-next .project-slider-container {
  background-color: #f2f3f4;
}
.swiper-slide.swiper-slide-next .project-slider-title {
  color: black;
}
.swiper-slide.swiper-slide-next .project-slider {
  color: black;
}
.swiper-slide.swiper-slide-next .ex-tag {
  background-color: #854ce630 !important;
  /* color: var(--text-primary-color);
  color: var(--primary-color); */

  color: var(--text-primary-color);
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  /* .swiper-slide.swiper-slide-active .project-slider-container {
    background-color: #f2f3f4;
  }*/
  .swiper-slide.swiper-slide-active .project-slider-title {
    color: var(--primary-color);
    text-align: left;
  }
  .swiper-slide.swiper-slide-active .project-slider-description {
    color: var(--primary-color);
  }
  .swiper-slide.swiper-slide-active .ex-tag {
    color: var(--primary-color);
  }
  .swiper-slide.swiper-slide-active .list-layout-button {
    color: var(--primary-color);
  }
}
/* .swiper-slide.swiper-slide-next + .swiper-slide{} */

.project-slider-container {
  padding: 0;
  padding-bottom: 30px;
  margin-bottom: 0;
}
.project-slider-title {
  padding: 0 10px;
}
.project-slider-description {
  padding: 0 10px;
  margin-top: 10px;
}

.project-slider-container img {
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
}
.project-slider-end-container {
  display: flex;
}
/* 
.blog-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
}

.blog-tag-content {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-primary-color);
  background: #854ce630;
  padding: 2px 8px;
  border-radius: 10px;
} */
.overview-section .item-icon {
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.overview-section .item-title {
  font-size: 1rem;
  color: var(--primary-color);
}
.overview-section .item-desc {
  color: hsla(0, 0%, 100%, 0.7);
}
.overview-section .tech-icon {
  max-height: 30px;
}
.divider {
  border: 1px solid var(--primary-color);
}
/* Project Page */

.list-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-page .project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-page .project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.project-page .card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}
.project-page .image-container {
  min-height: 350px;
  max-height: 350px;
}

.project-page .project-slider-container img {
  max-height: 350px;
}
.react-activity-calendar {
  /* color: var(--primary-color); */
}

.highlight {
  color: var(--text-primary-color);
}
